import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from '../constants/enumerators';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const isAuthenticated = Boolean(authToken);

  const logout = useCallback(() => {
    setAuthToken(null);
    setUser(null);
    setIsAdmin(false);
    localStorage.removeItem('authToken');
    navigate('/login');
  }, [navigate]);

  const fetchUser = useCallback(async () => {
    if (!authToken) {
      setUser(null);
      setIsAdmin(false);
      setIsLoading(false);
      return;
    }

    try {
      const { data } = await axios.get(`${API_URL}/profile`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setUser(data);
      setIsAdmin(data.admin);
    } catch (error) {
      logout();
    } finally {
      setIsLoading(false);
    }
  }, [authToken, logout]);

  useEffect(() => {
    if (authToken) {
      fetchUser();
    } else {
      setIsLoading(false);
    }
  }, [authToken, fetchUser]);

  const login = useCallback((token) => {
    setAuthToken(token);
    localStorage.setItem('authToken', token);
    setIsLoading(true);
    fetchUser();
  }, [fetchUser]);

  const handleOAuthLogin = (provider) => {
    window.location.href = `${API_URL}/auth/${provider}`;
  };

  const handleOAuthCallback = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');

    if (token) {
      login(token);
      navigate('/profile');
    } else {
      navigate('/login');
    }
  }, [login, navigate]);

  return (
    <AuthContext.Provider value={{
      authToken,
      user,
      isAdmin,
      isAuthenticated,
      login,
      logout,
      handleOAuthLogin,
      handleOAuthCallback,
      fetchUser,
      isLoading
    }}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};
