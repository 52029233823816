// App.js
import React, { lazy, Suspense, useEffect, useContext } from 'react';
import { Box, Container, CssBaseline, Toolbar, CircularProgress } from '@mui/material';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Menu from './components/common/Menu';
import Footer from './components/common/Footer';
import ProtectedRoute from './components/routes/ProtectedRoute';
import PublicRoute from './components/routes/PublicRoute';
import { SnackbarProvider } from './context/SnackbarContext';
import { AuthProvider, AuthContext } from './context/AuthContext';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { SITE_DOMAIN } from './constants/enumerators';

const loadable = (importFunc) => lazy(importFunc);

const ResetPassword = loadable(() => import('./components/pages/Auth/ResetPassword'));
const EmailConfirmation = loadable(() => import('./components/pages/Auth/EmailConfirmation'));
const Login = loadable(() => import('./components/pages/Auth/Login'));
const Register = loadable(() => import('./components/pages/Auth/Register'));
const ForgotPassword = loadable(() => import('./components/pages/Auth/ForgotPassword'));
const CreateTask = loadable(() => import('./components/pages/Tasks/CreateTask'));
const Tasks = loadable(() => import('./components/pages/Tasks/Tasks'));
const Task = loadable(() => import('./components/pages/Tasks/Task'));
const Pricing = loadable(() => import('./components/pages/Pricing'));
const ReverseSearch = loadable(() => import('./components/pages/ReverseSearch'));
const Profile = loadable(() => import('./components/pages/Profile/Profile'));
const Notifications = loadable(() => import('./components/pages/Profile/Notifications'));
const Legal = loadable(() => import('./components/pages/Legal'));
const PrivacyPolicy = loadable(() => import('./components/pages/PrivacyPolicy'));
const NotFound = loadable(() => import('./components/pages/NotFound'));
const Checkout = loadable(() => import('./components/pages/Checkout/Checkout'));
const CheckoutPayment = loadable(() => import('./components/pages/Checkout/CheckoutPayment'));
const CheckoutSuccess = loadable(() => import('./components/pages/Checkout/CheckoutSuccess'));
const Track = loadable(() => import('./components/pages/Track'));

const AdminRoute = loadable(() => import('./components/layout/AdminRoute'));
const AdminUsers = loadable(() => import('./components/pages/Admin/AdminUsers'));
const AdminInvoices = loadable(() => import('./components/pages/Admin/AdminInvoices'));
const AdminTasks = loadable(() => import('./components/pages/Admin/AdminTasks'));
const AdminSearches = loadable(() => import('./components/pages/Admin/AdminSearches'));
const AdminDash = loadable(() => import('./components/pages/Admin/AdminDash.js'));
const AdminNotifications = loadable(() => import('./components/pages/Admin/AdminNotifications'));

const OAuthCallback = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    if (token) {
      login(token);
      navigate('/profile');
    } else {
      navigate('/login');
    }
  }, [location.search, login, navigate]);

  return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
};

function App() {
  const navigate = useNavigate();

  const handleTaskCreated = (task) => {
    navigate(`/task/${task.task_id}`);
  };

  return (
    <AuthProvider>
      <SnackbarProvider>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <CssBaseline />
          <Menu />
          <Box component="main" sx={{ flexGrow: 1, p: { xs: 1, sm: 3 } }}>
            <Toolbar />
            <Container maxWidth="xl">
              <HelmetProvider>
                <Helmet>
                  <title>{SITE_DOMAIN || 'SubdomainRadar' } - Home</title>
                </Helmet>
              </HelmetProvider>
              <Suspense fallback={<Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>}>
                <Routes>
                  <Route path="/" element={<CreateTask onTaskCreated={handleTaskCreated} />} />
                  <Route path="/task/:task_id" element={<Task />} />
                  <Route path="/tasks" element={<ProtectedRoute><Tasks /></ProtectedRoute>} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/reverse" element={<ReverseSearch />} />
                  <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                  <Route path="/notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
                  <Route path="/legal" element={<Legal />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/checkout/success" element={<ProtectedRoute><CheckoutSuccess /></ProtectedRoute>} />
                  <Route path="/checkout/payment" element={<ProtectedRoute><CheckoutPayment /></ProtectedRoute>} />
                  <Route path="/checkout" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/admin/dashboard" element={<AdminRoute><AdminDash /></AdminRoute>} />
                  <Route path="/admin/users" element={<AdminRoute><AdminUsers /></AdminRoute>} />
                  <Route path="/admin/invoices" element={<AdminRoute><AdminInvoices /></AdminRoute>} />
                  <Route path="/admin/tasks" element={<AdminRoute><AdminTasks /></AdminRoute>} />
                  <Route path="/admin/searches" element={<AdminRoute><AdminSearches /></AdminRoute>} />
                  <Route path="/admin/notifications" element={<AdminRoute><AdminNotifications /></AdminRoute>} />
                  <Route path="/oauth-callback" element={<OAuthCallback />} />
                  <Route path="/confirm-email" element={<EmailConfirmation />} />
                  <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
                  <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />
                  <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
                  <Route path="/track" element={<Track />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <TawkMessengerReact propertyId="66992794becc2fed69272c6d" widgetId="1i33457oo"/>
              </Suspense>
            </Container>
          </Box>
          <Footer />
        </Box>
      </SnackbarProvider>
    </AuthProvider>
  );
}

export default App;
