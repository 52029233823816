// Fonction pour vérifier si une variable d'environnement est définie
const checkEnvVariable = (variableName, defaultValue = null) => {
    const value = process.env[variableName] || defaultValue;
    if (!value) {
      throw new Error(`Environment variable ${variableName} is not set!`);
    }
    return value;
  };
  
  // Utilisation de la fonction pour toutes les variables d'environnement
  export const SITE_NAME = checkEnvVariable('REACT_APP_SITE_NAME');
  export const SITE_DOMAIN = checkEnvVariable('REACT_APP_SITE_DOMAIN');
  export const SITE_URL = checkEnvVariable('REACT_APP_SITE_URL');
  export const SITE_EMAIL = checkEnvVariable('REACT_APP_SITE_EMAIL');
  export const API_URL = checkEnvVariable('REACT_APP_API_URL');
  
  // Autres constantes
  export const FREE_PROCESSORS_MAX = 100;
  export const REVERSE_SEARCH_POINT = 5;
  