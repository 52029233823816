// components/common/Menu.js
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar, Toolbar, Typography, Button, Box, IconButton, Menu as MuiMenu, MenuItem, ListItemText, Drawer, List, ListItem, ListItemButton,
} from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { Menu as MenuIcon, AccountCircle, AdminPanelSettings } from '@mui/icons-material';
import { AuthContext } from '../../context/AuthContext';
import { API_URL, SITE_DOMAIN } from '../../constants/enumerators';

function Menu() {
  const { user } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [adminMenuAnchor, setAdminMenuAnchor] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);
  const handleAdminMenuOpen = (event) => setAdminMenuAnchor(event.currentTarget);
  const handleAdminMenuClose = () => setAdminMenuAnchor(null);
  const handleUserMenuOpen = (event) => setUserMenuAnchor(event.currentTarget);
  const handleUserMenuClose = () => setUserMenuAnchor(null);

  const menuItems = [
    { text: 'Search', path: '/' },
    { text: 'Reverse Search', path: '/reverse' },
    { text: 'Pricing', path: '/pricing' },
    { text: 'API Docs', path: `${API_URL}/docs` },
  ];

  const renderMenuItems = () =>
    menuItems.map((item) => (
      <ListItem key={item.text} disablePadding>
        <ListItemButton component={Link} to={item.path}>
          <ListItemText primary={item.text} />
        </ListItemButton>
      </ListItem>
    ));

  const renderUserMenu = () =>
    user ? (
      <>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/tasks">
            <ListItemText primary="Tasks" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/profile">
            <AccountCircle sx={{ marginRight: 1 }} />
            <ListItemText primary="Profile" />
          </ListItemButton>
        </ListItem>
        {user.role === 'admin' && (
          <ListItem disablePadding>
            <ListItemButton onClick={handleAdminMenuOpen}>
              <ListItemText primary="Admin Menu" />
            </ListItemButton>
            <MuiMenu
              anchorEl={adminMenuAnchor}
              open={Boolean(adminMenuAnchor)}
              onClose={handleAdminMenuClose}
            >
              <MenuItem component={Link} to="/admin/dashboard" onClick={handleAdminMenuClose}>
                Admin Dashboard
              </MenuItem>
              <MenuItem component={Link} to="/admin/users" onClick={handleAdminMenuClose}>
                Manage Users
              </MenuItem>
            </MuiMenu>
          </ListItem>
        )}
      </>
    ) : (
      <>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/login">
            <ListItemText primary="Login" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/register">
            <ListItemText primary="Register" />
          </ListItemButton>
        </ListItem>
      </>
    );

  return (
    <AppBar position="static">
      <Toolbar>
        <Box component={Link} to="/" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
          <Typography variant="h6">
            {SITE_DOMAIN}
          </Typography>
        </Box>
        {isMobile ? (
          <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
              <Box sx={{ width: 250 }} role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
                <List>
                  {renderMenuItems()}
                  {renderUserMenu()}
                </List>
              </Box>
            </Drawer>
          </>
        ) : (
          <Box display="flex" alignItems="center">
            {menuItems.map((item) => (
              <Button key={item.text} color="inherit" component={Link} to={item.path} sx={{ marginRight: 2, '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>
                {item.text}
              </Button>
            ))}
            {user ? (
              <>
                <Button color="inherit" component={Link} to="/tasks" sx={{ marginRight: 2, '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>
                  Tasks
                </Button>
                <Button color="inherit" component={Link} to="/profile" sx={{ marginRight: 2, '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>
                  Points: {user.subscription_points}
                </Button>
                <IconButton color="inherit" onClick={handleUserMenuOpen}>
                  <AccountCircle />
                </IconButton>
                <MuiMenu
                  anchorEl={userMenuAnchor}
                  open={Boolean(userMenuAnchor)}
                  onClose={handleUserMenuClose}
                >
                  <MenuItem component={Link} to="/profile" onClick={handleUserMenuClose}>
                    Profile
                  </MenuItem>
                  <MenuItem component={Link} to="/notifications" onClick={handleUserMenuClose}>
                    Notifications
                  </MenuItem>
                </MuiMenu>
                {user.admin && (
                  <>
                    <IconButton color="inherit" onClick={handleAdminMenuOpen}>
                      <AdminPanelSettings />
                    </IconButton>
                    <MuiMenu
                      anchorEl={adminMenuAnchor}
                      open={Boolean(adminMenuAnchor)}
                      onClose={handleAdminMenuClose}
                    >
                      <MenuItem component={Link} to="/admin/dashboard" onClick={handleAdminMenuClose}>
                        Dashboard
                      </MenuItem>
                      <MenuItem component={Link} to="/admin/users" onClick={handleAdminMenuClose}>
                        Users
                      </MenuItem>
                      <MenuItem component={Link} to="/admin/invoices" onClick={handleAdminMenuClose}>
                        Invoices
                      </MenuItem>
                      <MenuItem component={Link} to="/admin/tasks" onClick={handleAdminMenuClose}>
                        Tasks
                      </MenuItem>
                      <MenuItem component={Link} to="/admin/searches" onClick={handleAdminMenuClose}>
                        Searches
                      </MenuItem>
                      <MenuItem component={Link} to="/admin/notifications" onClick={handleAdminMenuClose}>
                        Notifications
                      </MenuItem>
                    </MuiMenu>
                  </>
                )}
              </>
            ) : (
              <>
                <Button color="inherit" component={Link} to="/login" sx={{ marginRight: 2, '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>
                  Login
                </Button>
                <Button color="inherit" component={Link} to="/register" sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>
                  Register
                </Button>
              </>
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Menu;
