// context/SnackbarContext.js
import React, { createContext, useState, useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    type: 'success',
  });

  const showSnackbar = useCallback((message, type = 'success') => {
    setSnackbarState({ open: true, message, type });
  }, []);

  const closeSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={8000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={snackbarState.type} sx={{ width: '100%' }}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
