// components/common/Footer.js
import React from 'react';
import { Box, Typography, Link, Container, Grid, IconButton, Divider, Tooltip } from '@mui/material';
import { Reddit, Twitter, LinkedIn, CreditCard, MonetizationOn } from '@mui/icons-material';
import { API_URL, SITE_EMAIL, SITE_DOMAIN } from '../../constants/enumerators';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 6,
        backgroundColor: 'primary.dark',
        color: 'white',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              {SITE_DOMAIN}
            </Typography>
            <Typography variant="body2">
              &copy; {new Date().getFullYear()} { SITE_DOMAIN }. All rights reserved.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Contact
            </Typography>
            <Typography variant="body2">
              <Link color="inherit" underline="none" href={`mailto:${SITE_EMAIL}`}>
                {SITE_EMAIL}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <Box display="flex">
              <IconButton href="https://twitter.com/subdomainradar" color="inherit">
                <Twitter />
              </IconButton>
              <IconButton href="https://linkedin.com" color="inherit">
                <LinkedIn />
              </IconButton>
              <IconButton href="https://reddit.com/r/subdomainradar" color="inherit">
                <Reddit />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ my: 4, borderColor: 'white' }} />
        <Box textAlign="center">
          <Typography variant="body2">
            We accept:
            <Tooltip title="Credit Card">
              <IconButton color="inherit">
                <CreditCard />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cryptocurrency">
              <IconButton color="inherit">
                <MonetizationOn />
              </IconButton>
            </Tooltip>
          </Typography>
        </Box>
        <Divider sx={{ my: 4, borderColor: 'white' }} />
        <Typography variant="body2" align="center">
          <Link href="/legal" color="inherit" underline="always"  sx={{ mx: 2 }}>
            Legal Notice
          </Link>
          <Link href="/privacy" color="inherit" underline="always" sx={{ mx: 2 }}>
            Privacy Policy
          </Link>
          <Link href={`${API_URL}/docs`} color="inherit" underline="always" sx={{ mx: 2 }}>
            API Docs
          </Link>
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
